@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;700&display=swap');

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

.container-fluid {
  padding: 0;
  margin: 0;
  width: 100%;
  overflow: hidden;
}

.navbar {
  margin: 0;
  padding: 0;
  background-color: rgba(0, 0, 0, 0.7); /* Black background with 60% opacity */
  width: 100%;
}

.header {
  position: relative;
  height: 100vh;
  width: 100%;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  opacity: 0; /* Start hidden */
  transition: opacity 1s ease-in-out;
}

.header.visible {
  opacity: 1;
}

.header h1 {
  font-family: 'Work Sans', sans-serif;
  text-align: center;
  padding: 0 20px;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
}

.logo {
  height: 34.7vw; /* Logo size relative to viewport width */
  width: auto;
  margin-bottom: 20px;
}

@media (min-width: 576px) {
  .logo {
    height: 200px;
  }
}

.content {
  margin-top: 60px; /* Adjust based on your navbar height */
  padding-left: 0; /* Remove left padding */
  padding-right: 0; /* Remove right padding */
}

.upcoming-events {
  padding: 20px;
  text-align: center;
}

.upcoming-events h1 {
  font-family: 'Work Sans', sans-serif;
  color: white;
  margin-bottom: 40px;
}

.upcoming-events ul {
  list-style: none;
  padding: 0;
}

.upcoming-events li {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.highlights {
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.highlights.visible {
  font-family: 'Work Sans', sans-serif;
  color: white;
  opacity: 1;
}

.polaroid-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.polaroid-container {
  display: flex;
  overflow-x: auto; /* Enable horizontal scrolling */
  padding: 20px 0; /* Adjust padding to avoid left and right padding */
  -ms-overflow-style: none;  /* Hide scrollbar in IE and Edge */
  scrollbar-width: none;  /* Hide scrollbar in Firefox */
  scroll-behavior: smooth; /* Enable smooth scrolling */
  flex: 1;
}

.polaroid-container::-webkit-scrollbar {
  display: none;  /* Hide scrollbar in Chrome, Safari, and Opera */
}

.scroll-button {
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  color: white;
  font-size: 2rem;
  padding: 10px;
  cursor: pointer;
  z-index: 1;
}

.scroll-button.left {
  background-color: rgba(0, 0, 0, 0.1);
  position: absolute;
  left: 0;
}

.scroll-button.right {
  background-color: rgba(0, 0, 0, 0.1);
  position: absolute;
  right: 0;
}

.polaroid {
  margin: 10px;
  flex: 0 0 auto;
}

.polaroid-svg {
  width: 100%;
  height: auto;
}

/* Responsive styles */

@media (max-width: 415px) {
  .polaroid {
    width: 300px; /* Fixed width for larger screens */
    height: auto; /* Fixed height for larger screens */
    margin: 10px;
    flex: 0 0 auto;
  }

  .polaroid-svg {
    width: 100%;
    height: 100%;
  }
}

@media (min-width: 415px) {
  .polaroid {
    width: 400px; /* Fixed width for larger screens */
    height: auto; /* Fixed height for larger screens */
    margin: 10px;
    flex: 0 0 auto;
  }

  .polaroid-svg {
    width: 100%;
    height: 100%;
  }
}
