/* Remove arrows in number input field for webkit browsers (Chrome, Safari, etc.) */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Remove arrows in number input field for Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: 'Arial', sans-serif;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.join-us-background {
  background-color: #f0f0f0; /* Add a background color or use your image */
  background-image: url('./assets/images/JumpShot.webp');
  background-size: cover;
  background-position: center;
  background-attachment: fixed; /* Ensure the background image stays fixed and covers the viewport */
  height: 100vh; /* Make sure it takes full viewport height */
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadeIn 0.6s ease-in-out; /* Apply the fade-in animation */
  margin: 0; /* Reset margin */
}

.join-us-container {
  background-color: rgba(255, 255, 255, 0.7); /* Add transparency to show the background */
  border-radius: 8px;
  padding: 20px;
  max-width: 600px;
  width: 90%;
  box-sizing: border-box; /* Include padding and border in element's total width and height */
  animation: fadeIn 0.6s ease-in-out; /* Apply the fade-in animation */
  margin: 0; /* Reset margin */
  max-height: 80%;
  overflow-y: auto;
  margin-top: 40px;
}

h1 {
  color: #333;
  margin-bottom: 20px;
  text-align: center;
}

form {
  width: 100%;
  display: flex;
  flex-direction: column;
}

form div {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

label {
  margin-bottom: 5px;
  font-weight: bold;
  color: #555;
}

input,
select,
textarea {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: none;
  border-bottom: 2px solid #ccc;
  box-sizing: border-box; /* Include padding and border in element's total width and height */
  transition: border-bottom-color 0.3s ease;
}

input:focus,
select:focus,
textarea:focus {
  border-bottom-color: #007BFF;
  outline: none;
}

button {
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #007BFF;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #0056b3;
}

.optional {
  font-weight: normal;
  color: #888;
}
