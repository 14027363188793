html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

.footer {
  background-color: #f9f9f9;
  padding: 20px 0;
  text-align: center;
  width: 100%; /* Ensure it spans the full width */
  margin: 0; /* Reset margin */
}

.footer-content {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.footer-section {
  flex: 1;
  min-width: 300px;
  margin: 10px;
}

.footer-logo {
  width: 100px;
  margin-bottom: 10px;
}

.footer-section p {
  margin: 5px 0;
  color: #333;
}

.footer-social a {
  margin: 0 10px;
  color: #333;
  font-size: 20px;
}

.footer-social a:hover {
  color: #007BFF; /* Change to your brand color */
}

.footer-bottom {
  background-color: #f1f1f1;
  padding: 10px 0;
  margin-top: 20px;
}

.footer-bottom p {
  margin: 5px 0;
  color: #333;
}

.footer-bottom a {
  color: #007BFF; /* Change to your brand color */
  text-decoration: none;
}

.footer-bottom a:hover {
  text-decoration: underline;
}
