/* UpcomingEvent.css */
.upcoming-event {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #f9f9f9;
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
  }
  
  .event-image-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .event-image {
    width: 100%;
    height: auto;
    max-height: 200px;
    object-fit: contain;
    border-radius: 8px;
  }
  
  .event-details {
    width: 100%;
    padding: 20px 0;
    text-align: center;
  }
  
  .event-details h2 {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  .event-details h3 {
    font-size: 1.2rem;
    margin-bottom: 10px;
    color: #777;
  }
  
  .event-details p {
    font-size: 1rem;
    margin-bottom: 10px;
  }
  
  .event-description {
    color: black;
  }
  
  .event-button {
    background-color: #000;
    color: #fff;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    font-size: 1rem;
    text-decoration: none;
    display: inline-block;
    text-align: center;
    margin-top: 10px;
    border-radius: 4px;
  }
  
  .event-navigation {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    width: 100%;
  }
  
  .nav-button {
    background-color: #ddd;
    border: none;
    padding: 10px;
    cursor: pointer;
    font-size: 1rem;
    border-radius: 4px;
  }
  
  @media (min-width: 900px) {
    .upcoming-event {
      flex-direction: row;
      align-items: flex-start;
    }
  
    .event-image-container {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  
    .event-image {
      width: auto;
      height: 300px;
      max-height: none;
      object-fit: contain;
    }
  
    .event-details {
      flex: 2;
      padding: 0 20px;
      text-align: center;
      position: relative;
    }
  
    .event-navigation {
      justify-content: space-between;
      margin-top: 20px;
      width: 100%;
    }
  
    .nav-button:first-child {
      margin-right: auto;
    }
  
    .nav-button:last-child {
      margin-left: auto;
    }
  }
  